.categories-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1rem;
}

.categories-scroll {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  min-width: max-content;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
}

.category-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-name {
  font-size: 12px;
  text-align: center;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.category.selected .category-name {
  font-weight: bold;
}

/* Add responsive styles */
@media (max-width: 768px) {
  .category-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .category-name {
    max-width: 50px;
  }
}