@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

body {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
}

.main-content {
  flex: 1;
}

.container {
  max-width: 32rem;
  margin: 0 auto;
  padding: 1rem;
}

.posts {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 768px) {
  .app {
    padding: 0; /* Remove any default padding */
  }
  .main-content {
    padding: 0; /* Remove any default padding */
  }
  .container {
    max-width: 100%; /* Ensure full width */
    padding: 0; /* Remove padding to avoid gaps */
    margin: 0; /* Remove margin to avoid gaps */
  }
  .posts {
    margin: 0; /* Remove bottom margin */
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }
}