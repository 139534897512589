.post {
  background-color: white;
  overflow: hidden;
  margin-bottom: 1rem;
  border-bottom: 0.8px solid #d2d0d0;
}

.post-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.post-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.post-user-info {
  display: flex;
  flex-direction: column;
}

.post-location {
  font-size: 12px;
  padding-top: 0.2rem;
  color: #6b7280;
}

.post-date {
  font-size: 12px;
  color: #6b7280;
  font-weight: 600;
}

.post-footer {
  padding: 0.75rem;
}

.post-actions {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.post-description {
  color: #1f2937;
}

.post-username {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}

.post-username:hover {
  text-decoration: underline;
}

.post-image a {
  display: block;
}

.post-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

@media (max-width: 768px) {
  .post {
    margin: 0; /* Remove bottom margin */
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }
}